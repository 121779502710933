import { axios } from "../utils/axios";
import { routeToService } from "../utils/routeToService";

const SERVICE = {
  Ayandeh: () =>
    routeToService(
      getLocaleApi("Ayandeh.aspx", "AyandehEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  Banner: () =>
    routeToService(
      "Banner.aspx",
      process.env.REACT_APP_BASE_URL
    ),
  ContactForm: (params?: any) =>
    routeToService(
      `ContactForm.aspx?description=${params.description}&email=${params.email}&name=${params.name}&tel=${params.tel}`,
      process.env.REACT_APP_BASE_URL
    ),
  ContactUs: () =>
    routeToService(
      getLocaleApi("ContactUs.aspx", "ContactUsEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),

  HeyatModire: () =>
    routeToService(
      getLocaleApi("HeyatModire.aspx", "HeyatModireEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  Home: () =>
    routeToService(
      "Home.aspx",
      process.env.REACT_APP_BASE_URL
    ),
  Jamee: () =>
    routeToService(
      getLocaleApi("Jamee.aspx", "JameeEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  Javayez: () =>
    routeToService(
      getLocaleApi("Javayez.aspx", "JavayezEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  Mamoriyat: () =>
    routeToService(
      getLocaleApi("Mamoriyat.aspx", "MamoriyatEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  Menu: () =>
    routeToService(
      "Menu.aspx",
      process.env.REACT_APP_BASE_URL
    ),
  Sanat: () =>
    routeToService(
      getLocaleApi("Sanat.aspx", "SanatEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  SanatJahan: () =>
    routeToService(
      getLocaleApi("SanatJahan.aspx", "SanatJahanEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  Towers: () =>
    routeToService(
      getLocaleApi("Towers.aspx", "TowersEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
  TowersJahan: () =>
    routeToService(
      getLocaleApi("TowersJahan.aspx", "TowersJahanEN.aspx"),

      process.env.REACT_APP_BASE_URL
    ),
  Zinafan: () =>
    routeToService(
      getLocaleApi("Zinafan.aspx", "ZinafanEN.aspx"),
      process.env.REACT_APP_BASE_URL
    ),
};

const getLocaleApi = (faApi: string, enApi: string): string => {
  return (localStorage.getItem('lang') || 'fa') == 'fa' ? faApi : enApi;
}

export const AyandehApi = () => axios.get(SERVICE.Ayandeh());
export const BannerApi = () => axios.get(SERVICE.Banner());
export const ContactFormApi = (params?: any) => axios.get(SERVICE.ContactForm(params));
export const ContactUsApi = () => axios.get(SERVICE.ContactUs());
export const HeyatModireApi = () => axios.get(SERVICE.HeyatModire());
export const HomeApi = () => axios.get(SERVICE.Home());
export const JameeApi = () => axios.get(SERVICE.Jamee());
export const JavayezApi = () => axios.get(SERVICE.Javayez());
export const MamoriyatApi = () => axios.get(SERVICE.Mamoriyat());
export const MenuApi = () => axios.get(SERVICE.Menu());
export const SanatApi = () => axios.get(SERVICE.Sanat());
export const SanatJahanApi = () => axios.get(SERVICE.SanatJahan());
export const TowersApi = () => axios.get(SERVICE.Towers());
export const TowersJahanApi = () => axios.get(SERVICE.TowersJahan());
export const ZinafanApi = () => axios.get(SERVICE.Zinafan());