import React, { FC } from "react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer/Footer";
import Navbar from "./navbar";
import { useLocation } from "react-router-dom";

const Index: FC = () => {
  const location = useLocation();

  // useEffect(() => {
  //   console.log(location.pathname);
  // }, [useLocation()]);

  return (
    <>
      <Navbar path={location.pathname} />
      <div
        style={{ margin: "80px 0 10px 0", minHeight: "calc(100vh - 714px)" }}
      >
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Index;
