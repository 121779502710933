import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { slide as BMenu } from "react-burger-menu";
import { NavLink, useLocation } from "react-router-dom";
import { BannerApi, MenuApi } from "../../../../api";
import { getLocaleText } from "../../../../utils/getLocaleText";
import "./Menu.css";

function Menu() {
  const [lang, setLang] = useState("en");
  const [header, setHeader] = useState("");

  // This function put query that helps to
  // change the language
  const handleChange = (language: any) => {
    setLang(language);
    let loc = "http://localhost:3000/";
    window.location.replace(loc + "?lng=" + language);
    localStorage.setItem("lang", language);
    window.location.reload();
  };

  const isLanguage = (ExpectedLanguage: string): boolean => {
    return (localStorage.getItem("lang") || "fa") == ExpectedLanguage
      ? true
      : false;
  };

  const [isSystemsOpened, openSystems] = useState(false);
  const [isTowerCompany, openTowerCompany] = useState(false);
  const [isAboutUsOpened, openAboutUs] = useState(false);
  const [isProductsOpened, openProducts] = useState(false);
  let banner = "";

  const [menu, setMenu] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    MenuApi().then((response: AxiosResponse) => {
      let tempMenu = [];
      for (let index = 0; index < response.data.length; index++) {
        tempMenu.push(
          <li onClick={() => setIsMenuOpen(false)}>
            <a target={"_blank"} href={response.data[index].link}>
              {getLocaleText(
                response.data[index].title,
                response.data[index].titleEN
              )}
            </a>
          </li>
        );
      }
      setMenu(tempMenu as any);
    });
  }, []);
  const url = useLocation();
  useEffect(() => {
    switch (url.pathname) {
      case "/":
        getBanner(1);
        break;
      case "/towerCompany/0":
        getBanner(9);
        break;
      case "/towerCompany/1":
        getBanner(9);
        break;
      case "/towerCompany/2":
        getBanner(9);
        break;
      case "/towerCompany/3":
        getBanner(9);
        break;
      case "/b2s":
        getBanner(8);
        break;
      case "/blb":
        getBanner(8);
        break;
      case "/mll":
        getBanner(8);
        break;
      case "/operatorSites":
        getBanner(8);
        break;
      case "/beautification":
        getBanner(8);
        break;
      case "/futurePlan":
        getBanner(7);
        break;
      case "/society":
        getBanner(5);
        break;
      case "/mission":
        getBanner(2);
        break;
      case "/shareholders":
        getBanner(2);
        break;
      case "/beneficiaries":
        getBanner(4);
        break;
      case "/contactUs":
        getBanner(6);
        break;

      default:
        break;
    }
  }, []);

  const getBanner = (id: number): void => {
    BannerApi().then((response: AxiosResponse) => {
      // console.log("Banner Object", response.data);
      banner = response.data.find((item: any) => item.id === id).banner1;
      setHeader(banner);
    });
  };

  const getLocaleMenu = (faText: string, enText: string): string => {
    return (localStorage.getItem("lang") || "fa") == "fa" ? faText : enText;
  };

  const find = (): void => {};

  return (
    <div
      className="bg-no-repeat bg-cover menu"
      style={{
        backgroundImage: `linear-gradient(
      ${getLocaleText("90deg", "270deg")},
      rgba(34, 34, 34, 0) 12.85%,
      rgba(25, 29, 50, 0.83125) 66.92%,
      rgba(24, 29, 52, 0.924446) 91.32%,
      rgba(24, 29, 52, 0.95) 104.72%
    ),url(${header})`,
      }}
    >
      <div className="row desktop">
        <div className="col-10 right-menu">
          <img src="../assets/images/svg/logo.svg" />
          <div className="menu-items-wrapper">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "menu-items-active" : ""
              }
              onClick={() => getBanner(1)}
            >
              <span className="route">
                {getLocaleMenu("صفحه اصلی", "Home")}
              </span>
              &nbsp;
            </NavLink>
            <span
              style={{ position: "relative", zIndex: "1" }}
              onMouseEnter={() => openTowerCompany(!isTowerCompany)}
              onMouseLeave={() => openTowerCompany(!isTowerCompany)}
            >
              <span className={`route ${isTowerCompany ? "active-route" : ""}`}>
                {getLocaleMenu("تاور کمپانی", "What is Tower Company?")}
              </span>
              <div
                className={`sub-menu ${isTowerCompany ? "show-sub-menu" : ""}`}
              >
                <img
                  src="../assets/images/svg/red-arrow-down.svg"
                  className="arrow-down"
                />
                <ul className={getLocaleText("text-right", "text-left")}>
                  <li>
                    <NavLink
                      to="/towerCompany/0"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(9)}
                    >
                      {getLocaleMenu(
                        "تاور کمپانی چیست؟",
                        "What is Tower Company?"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/towerCompany/1"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(9)}
                    >
                      {getLocaleMenu(
                        "صنعت تاور در ایران",
                        "Tower industry in Iran"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/towerCompany/2"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(9)}
                    >
                      {getLocaleMenu(
                        "صنعت تاور در دنیا",
                        "Tower industry in the world"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/towerCompany/3"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(9)}
                    >
                      {getLocaleMenu(
                        "تاور کمپانی در ایران و جهان",
                        "Tower Company in Iran, the World"
                      )}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </span>
            <span
              style={{ position: "relative", zIndex: "1" }}
              onMouseEnter={() => openProducts(!isProductsOpened)}
              onMouseLeave={() => openProducts(!isProductsOpened)}
            >
              <span
                className={`route ${isProductsOpened ? "active-route" : ""}`}
              >
                {getLocaleMenu("محصولات و خدمات", "Products & Services")}
              </span>
              <div
                className={`sub-menu ${
                  isProductsOpened ? "show-sub-menu" : ""
                }`}
              >
                <img
                  src="../assets/images/svg/red-arrow-down.svg"
                  className="arrow-down"
                />
                <ul className={getLocaleText("text-right", "text-left")}>
                  <li>
                    <NavLink
                      to="/b2s"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(8)}
                    >
                      {getLocaleText(
                        "احداث سایت به روش B2S",
                        "Building a site using the B2S method"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/blb"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(8)}
                    >
                      {getLocaleText(
                        "خدمات فروش به شرط اجاره BLB",
                        "Sales service subject to BLB rental"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/mll"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(8)}
                    >
                      {getLocaleText(
                        "اجاره حق بهره برداری و مدیریت سایت MLL",
                        "Renting the right to operate and manage the MLL site"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/operatorSites"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(8)}
                    >
                      {getLocaleText(
                        "نگهداری سایت های مخابراتی",
                        "Maintenance of telecommunication sites"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/beautification"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(8)}
                    >
                      {getLocaleText(
                        "زیباسازی سایت های مخابراتی",
                        "Beautification of telecommunication sites"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/futurePlan"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(7)}
                    >
                      {getLocaleText("طرح های آینده", "Future Plans")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </span>

            <span
              style={{ position: "relative", zIndex: "1" }}
              onMouseEnter={() => openAboutUs(!isAboutUsOpened)}
              onMouseLeave={() => openAboutUs(!isAboutUsOpened)}
            >
              <span
                className={`route ${isAboutUsOpened ? "active-route" : ""}`}
              >
                {getLocaleMenu("درباره ما", "About US")}
              </span>
              <div
                className={`sub-menu ${isAboutUsOpened ? "show-sub-menu" : ""}`}
              >
                <img
                  src="../assets/images/svg/red-arrow-down.svg"
                  className="arrow-down"
                />
                <ul className={getLocaleText("text-right", "text-left")}>
                  <li>
                    <NavLink
                      to="/society"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(5)}
                    >
                      {getLocaleMenu("نقش ما در جامعه", "Our Rule in Society")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/mission"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(2)}
                    >
                      {getLocaleMenu("ماموریت، چشم انداز", "Mission & Vision")}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/shareholders"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(2)}
                    >
                      {getLocaleMenu(
                        "سهام‌داران و ذینفعان",
                        "Shareholders & stakeholders"
                      )}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/beneficiaries"
                      className={({ isActive }) =>
                        isActive ? "menu-items-active" : ""
                      }
                      onClick={() => getBanner(4)}
                    >
                      {getLocaleMenu("جوایز، تندیس ها", "Awards, statues")}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </span>
            <NavLink
              to="/contactUs"
              className={({ isActive }) =>
                isActive ? "menu-items-active" : ""
              }
              onClick={() => getBanner(6)}
            >
              <span className="route">
                {getLocaleMenu("ارتباط با ما", "Contact Us")}
              </span>
            </NavLink>
            <span
              style={{ position: "relative", zIndex: "1" }}
              onMouseEnter={() => openSystems(!isSystemsOpened)}
              onMouseLeave={() => openSystems(!isSystemsOpened)}
            >
              <span
                className={`route ${isSystemsOpened ? "active-route" : ""}`}
              >
                {getLocaleMenu("سامانه ها", "Systems")}
              </span>
              <div
                className={`sub-menu ${isSystemsOpened ? "show-sub-menu" : ""}`}
              >
                <img
                  src="../assets/images/svg/red-arrow-down.svg"
                  className="arrow-down"
                />
                <ul>{menu}</ul>
              </div>
            </span>
          </div>
        </div>
        <div className="col-2 left-side">
          <img src="../assets/images/svg/search.svg" />
          <div className="language-wrapper">
            <span className="language-label">
              {getLocaleMenu("زبان", "Lang")}
            </span>
            &nbsp;
            <span
              className={`language ${
                isLanguage("en") ? "language-active" : ""
              }`}
              onClick={() => handleChange("en")}
            >
              EN
            </span>
            &nbsp;|&nbsp;
            <span
              className={`language ${
                isLanguage("fa") ? "language-active" : ""
              }`}
              onClick={() => handleChange("fa")}
            >
              FA
            </span>
          </div>
        </div>
      </div>

      <div className="flex-row justify-between px-4 hamberger">
        <div className="cursor-pointer" onClick={() => setIsMenuOpen(true)}>
          <img src="../assets/images/svg/menu.svg" />
        </div>
        <div className="left-side">
          <img onClick={() => find()} src="../assets/images/svg/search.svg" />
          <div className="language-wrapper">
            <span
              className={getLocaleText("language-label", "language-label-en")}
            >
              {getLocaleMenu("زبان", "Lang")}
            </span>
            <span
              className={`language ${
                isLanguage("en") ? "language-active" : ""
              }`}
              onClick={() => handleChange("en")}
            >
              EN
            </span>
            &nbsp;|&nbsp;
            <span
              className={`language ${
                isLanguage("fa") ? "language-active" : ""
              }`}
              onClick={() => handleChange("fa")}
            >
              FA
            </span>
          </div>
        </div>
      </div>
      <BMenu
        isOpen={isMenuOpen}
        right={!getLocaleText("", "true")}
        customBurgerIcon={false}
        className="sm:!w-[100vwx] !w-full burger-menu"
        noOverlay
      >
        <div className="flex flex-col">
          <div className="flex self-stretch justify-between px-16 pt-12">
            <button
              className="text-sm font-bold text-white bg-transparent border-none cursor-pointer"
              onClick={() => setIsMenuOpen(false)}
            >
              X
            </button>
            <img src="../assets/images/svg/logo.svg" />
          </div>
          <div className="flex flex-col items-center gap-4">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "menu-items-active" : ""
              }
              onClick={() => {
                setIsMenuOpen(false);
                getBanner(1);
              }}
            >
              <span className="route">
                {getLocaleMenu("صفحه اصلی", "Home")}
              </span>
            </NavLink>
            <div
              style={{ position: "relative", zIndex: "1" }}
              onClick={() => {
                openTowerCompany(!isTowerCompany);
              }}
              className="flex flex-col cursor-pointer"
            >
              <span
                className={`route text-center ${
                  isTowerCompany ? "active-route" : ""
                }`}
              >
                {getLocaleMenu("تاور کمپانی", "Tower Company")}
              </span>
              {isTowerCompany && (
                <div className={`flex flex-col items-start`}>
                  <img
                    src="../assets/images/svg/red-arrow-down.svg"
                    className="self-center my-2 arrow-down"
                  />
                  <ul className="flex flex-col gap-2">
                    <li>
                      <NavLink
                        to="/towerCompany/0"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(9);
                        }}
                      >
                        {getLocaleMenu(
                          "تاور کمپانی چیست؟",
                          "What is Tower Company?"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/towerCompany/1"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(9);
                        }}
                      >
                        {getLocaleMenu(
                          "صنعت تاور در ایران",
                          "Tower industry in the Iran"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/towerCompany/2"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(9);
                        }}
                      >
                        {getLocaleMenu(
                          "صنعت تاور در دنیا",
                          "Tower industry in the world"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/towerCompany/3"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(9);
                        }}
                      >
                        {getLocaleMenu(
                          "تاور کمپانی در ایران و جهان",
                          "Tower Company in Iran, the World"
                        )}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div
              style={{ position: "relative", zIndex: "1" }}
              onClick={() => {
                openProducts(!isProductsOpened);
              }}
              className="flex flex-col cursor-pointer"
            >
              <span
                className={`route text-center ${
                  isProductsOpened ? "active-route" : ""
                }`}
              >
                {getLocaleMenu("محصولات و خدمات", "Products & Services")}
              </span>
              {isProductsOpened && (
                <div className={`flex flex-col items-start`}>
                  <img
                    src="../assets/images/svg/red-arrow-down.svg"
                    className="self-center my-2 arrow-down"
                  />
                  <ul className="flex flex-col gap-2">
                    <li>
                      <NavLink
                        to="/b2s"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(8);
                        }}
                      >
                        {getLocaleText(
                          "احداث سایت به روش B2S",
                          "Building a site using the B2S method"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/blb"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(8);
                        }}
                      >
                        {getLocaleText(
                          "خدمات فروش به شرط اجاره BLB",
                          "Sales service subject to BLB rental"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/mll"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(8);
                        }}
                      >
                        {getLocaleText(
                          "اجاره حق بهره برداری و مدیریت سایت MLL",
                          "Renting the right to operate and manage the MLL site"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/operatorSites"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(8);
                        }}
                      >
                        {getLocaleText(
                          "نگهداری سایت های مخابراتی",
                          "Maintenance of telecommunication sites"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/beautification"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(8);
                        }}
                      >
                        {getLocaleText(
                          "زیباسازی سایت های مخابراتی",
                          "Beautification of telecommunication sites"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/futurePlan"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(7);
                        }}
                      >
                        {getLocaleText("طرح های آینده", "Future Plans")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <div
              style={{ position: "relative", zIndex: "1" }}
              onClick={() => {
                openAboutUs(!isAboutUsOpened);
              }}
              className="flex flex-col cursor-pointer"
            >
              <span
                className={`route text-center ${
                  isAboutUsOpened ? "active-route" : ""
                }`}
              >
                {getLocaleMenu("درباره ما", "About US")}
              </span>
              {isAboutUsOpened && (
                <div className={`flex flex-col items-start`}>
                  <img
                    src="../assets/images/svg/red-arrow-down.svg"
                    className="self-center my-2 arrow-down"
                  />
                  <ul className="flex flex-col gap-2">
                    <li>
                      <NavLink
                        to="/society"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(5);
                        }}
                      >
                        {getLocaleMenu(
                          "نقش ما در جامعه",
                          "Our Rule in Society"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/mission"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(2);
                        }}
                      >
                        {getLocaleMenu(
                          "ماموریت، چشم انداز",
                          "Mission & Vision"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/shareholders"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(2);
                        }}
                      >
                        {getLocaleMenu(
                          "سهام‌داران و ذینفعان",
                          "Shareholders & stakeholders"
                        )}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/beneficiaries"
                        className={({ isActive }) =>
                          isActive ? "menu-items-active" : ""
                        }
                        onClick={() => {
                          setIsMenuOpen(false);
                          getBanner(4);
                        }}
                      >
                        {getLocaleMenu("جوایز، تندیس ها", "Awards, statues")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <NavLink
              to="/contactUs"
              className={({ isActive }) =>
                isActive ? "menu-items-active" : ""
              }
              onClick={() => {
                setIsMenuOpen(false);
                getBanner(6);
              }}
            >
              <span className="route">
                {getLocaleMenu("ارتباط با ما", "Contact Us")}
              </span>
            </NavLink>
            <div
              style={{ position: "relative", zIndex: "1" }}
              onClick={() => {
                openSystems(!isSystemsOpened);
              }}
              className="flex flex-col cursor-pointer"
            >
              <span
                className={`route text-center ${
                  isSystemsOpened ? "active-route" : ""
                }`}
              >
                {getLocaleMenu("سامانه ها", "Systems")}
              </span>
              {isSystemsOpened && (
                <div className={`flex flex-col items-start`}>
                  <img
                    src="../assets/images/svg/red-arrow-down.svg"
                    className="self-center my-2 arrow-down"
                  />
                  <ul className="flex flex-col gap-2">{menu}</ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </BMenu>
    </div>
  );
}

export default Menu;
