import "./Footer.css";
import { useEffect, useState } from "react";
import { useLocation, Link, NavLink } from "react-router-dom";
import { ContactUsApi } from "../../../api";
import { AxiosResponse } from "axios";

function Footer() {
  const [data, setData] = useState({
    tel: "",
    aboutusfooter: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    ContactUsApi().then((response: AxiosResponse) => setData(response.data[0]));
  }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getLocaleText = (faText: string, enText: string): string => {
    return (localStorage.getItem("lang") || "fa") == "fa" ? faText : enText;
  };

  return (
    <div
      className={`row footer ${getLocaleText(
        "md:!text-right text-center",
        "md:!text-left text-center"
      )}`}
      style={{ paddingBottom: "100px" }}
    >
      <div className="col-12 col-lg-4">
        <h1 className="about-tower-title">
          {getLocaleText("درباره تاورکمپانی", "About Tower Company")}
        </h1>
        <p className="about-tower-desc" style={{ textAlign: "justify" }}>
          {data.aboutusfooter}
        </p>
      </div>
      <div className="col-12 col-lg-2">
        <h3 className="menu-items">
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "menu-items-active" : "")}
          >
            {getLocaleText("صفحه اصلی", "Main Page")}
          </NavLink>
        </h3>
        <h3 className="menu-items">
          <NavLink
            to="/society"
            className={({ isActive }) => (isActive ? "menu-items-active" : "")}
          >
            {getLocaleText("درباره ما", "About Us")}
          </NavLink>
        </h3>
        <h3 className="menu-items">
          <NavLink
            to="/towerCompany/0"
            className={({ isActive }) => (isActive ? "menu-items-active" : "")}
          >
            {getLocaleText("تاور کمپانی", "Tower Company")}
          </NavLink>
        </h3>
      </div>
      <div className="col-12 col-lg-2">
        <h3 className="menu-items">
          <NavLink
            to="/contactUs"
            className={({ isActive }) => (isActive ? "menu-items-active" : "")}
          >
            {getLocaleText("ارتباط با ما", "Contact Us")}
          </NavLink>
        </h3>
        <h3 className="menu-items">
          <NavLink
            to="/shareHolders"
            className={({ isActive }) => (isActive ? "menu-items-active" : "")}
          >
            {getLocaleText(
              "سهام‌داران و ذینفعان",
              "Shareholders & stackholders"
            )}
          </NavLink>
        </h3>
        <h3 className="menu-items">
          <NavLink
            to="/b2s"
            className={({ isActive }) => (isActive ? "menu-items-active" : "")}
          >
            {getLocaleText("محصولات و خدمات", "Products & Services")}
          </NavLink>
        </h3>
      </div>
      <div className="col-12 col-lg-4">
        <div className="row">
          <p className="address-data">
            <span className="bold">
              {getLocaleText("آدرس", "Address")}
              :&nbsp;
            </span>
            {data.address}
          </p>
        </div>
        <div className="row">
          <p className="address-data">
            <span className="bold">
              {getLocaleText("ایمیل", "Email")}
              :&nbsp;
            </span>
            {data.email}
          </p>
        </div>
        <div className="row">
          <p className="address-data">
            <span className="bold">
              {getLocaleText("شماره تماس", "Tel")}
              :&nbsp;
            </span>
            {data!.tel}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
