import loadable, { LoadableComponent } from "@loadable/component";

const FuturePlan = loadable(() => import("../../pages/futurePlan/FuturePlan"));
const OperatorSites = loadable(() => import("../../pages/operatorSites/OperatorSites"));
const MLL = loadable(() => import("../../pages/mll/MLL"));
const BLB = loadable(() => import("../../pages/blb/BLB"));
const B2S = loadable(() => import("../../pages/b2s/B2S"));
const Beneficiaries = loadable(() => import("../../pages/beneficiaries/Beneficiaries"));
const Shareholders = loadable(() => import("../../pages/shareholders/Shareholders"));
const TowerCompany = loadable(() => import("../../pages/towerCompany/TowerCompany"));
const ContactUs = loadable(() => import("../../pages/contactUs/ContactUs"));
const Products = loadable(() => import("../../pages/products/Products"));
const Society = loadable(() => import("../../pages/society/Society"));
const Home = loadable(() => import("../../pages/home/Home"));
const Mission = loadable(() => import("../../pages/mission/Mission"));
const Beautification = loadable(() => import("../../pages/beautification/Beautification"));

interface IRoute {
  title: string;
  path: string;
  isPrivate?: boolean;
  hasMenuField: boolean;
  hasParent?: any[];
  icon?: any;
  children?: IRoute[];
  element?: LoadableComponent<unknown>;
}

interface IRoutesName {
  [name: string]: {
    title: string;
    path: string;
  };
}

export const routesName: IRoutesName = {
  default: {
    title: "default",
    path: "/",
  },
  Society: {
    title: "society",
    path: "/society",
  },
  Products: {
    title: "products",
    path: "/products",
  },
  ContactUs: {
    title: "contactUs",
    path: "/contactUs",
  },
  TowerCompany: {
    title: "towerCompany",
    path: "/towerCompany/:id",
  },
  Shareholders: {
    title: "shareholders",
    path: "/shareholders",
  },
  Beneficiaries: {
    title: "beneficiaries",
    path: "/beneficiaries",
  },
  B2S: {
    title: "b2s",
    path: "/b2s",
  },
  BLB: {
    title: "blb",
    path: "/blb",
  },
  MLL: {
    title: "mll",
    path: "/mll",
  },
  OperatorSites: {
    title: "operatorSites",
    path: "/operatorSites",
  },
  FuturePlan: {
    title: "futurePlan",
    path: "/futurePlan",
  },
  Mission: {
    title: "mission",
    path: "/mission",
  },
  Beautification: {
    title: "beautification",
    path: "/beautification",
  },
};

const routes: IRoute[] = [
  {
    ...routesName.default,
    isPrivate: false,
    hasMenuField: false,
    element: Home,
  },
  {
    ...routesName.Society,
    isPrivate: false,
    hasMenuField: false,
    element: Society,
  },
  {
    ...routesName.Products,
    isPrivate: false,
    hasMenuField: false,
    element: Products,
  },
  {
    ...routesName.ContactUs,
    isPrivate: false,
    hasMenuField: false,
    element: ContactUs,
  },
  {
    ...routesName.TowerCompany,
    isPrivate: false,
    hasMenuField: false,
    element: TowerCompany,
  },
  {
    ...routesName.Shareholders,
    isPrivate: false,
    hasMenuField: false,
    element: Shareholders,
  },
  {
    ...routesName.Beneficiaries,
    isPrivate: false,
    hasMenuField: false,
    element: Beneficiaries,
  },
  {
    ...routesName.B2S,
    isPrivate: false,
    hasMenuField: false,
    element: B2S,
  },
  {
    ...routesName.BLB,
    isPrivate: false,
    hasMenuField: false,
    element: BLB,
  },
  {
    ...routesName.MLL,
    isPrivate: false,
    hasMenuField: false,
    element: MLL,
  },
  {
    ...routesName.OperatorSites,
    isPrivate: false,
    hasMenuField: false,
    element: OperatorSites,
  },
  {
    ...routesName.FuturePlan,
    isPrivate: false,
    hasMenuField: false,
    element: FuturePlan,
  },
  {
    ...routesName.Mission,
    isPrivate: false,
    hasMenuField: false,
    element: Mission,
  },
  {
    ...routesName.Beautification,
    isPrivate: false,
    hasMenuField: false,
    element: Beautification,
  }
];

export { routes };
export type { IRoute };
