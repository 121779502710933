import { Link } from "react-router-dom";
import Menu from "../home/components/menu/Menu";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { HomeApi } from "../../api";
import { getLocaleText } from "../../utils/getLocaleText";

function Navbar(props: any) {
  const [photo1, setPhoto1] = useState();
  const [photo2, setPhoto2] = useState();
  const [photo3, setPhoto3] = useState();
  const [photo4, setPhoto4] = useState();
  const [photo5, setPhoto5] = useState();
  const [photo6, setPhoto6] = useState();
  const [suntitle, setSuntitle] = useState("");
  const [suntitleEN, setSuntitleEN] = useState("");
  const [title, setTitle] = useState("");
  const [titleEN, setTitleEN] = useState("");
  const [loc, setLoc] = useState();
  const [locTitle, setLocTitle] = useState("");

  useEffect(() => {
    HomeApi().then((response: AxiosResponse) => {
      setPhoto1(response.data[0].photo1);
      setPhoto2(response.data[0].photo2);
      setPhoto3(response.data[0].photo3);
      setPhoto4(response.data[0].photo4);
      setPhoto5(response.data[0].photo5);
      setPhoto6(response.data[0].photo6);
      setSuntitle(response.data[0].suntitle);
      setSuntitleEN(response.data[0].suntitleEN);
      setTitle(response.data[0].title);
      setTitleEN(response.data[0].titleEN);
      // console.log("Home Api", response.data);
    });
  }, []);

  useEffect(() => {
    setLoc(props.path);
    if (props.path === "/") {
      setLocTitle("صفحه اصلی");
    }

    if (props.path === "/towerCompany/0") {
      setLocTitle(getLocaleText("تاور کمپانی", "Tower Company"));
    }

    if (props.path === "/towerCompany/1") {
      setLocTitle(
        getLocaleText("تاور کمپانی در ایران", "Tower Company in Iran")
      );
    }

    if (props.path === "/towerCompany/2") {
      setLocTitle(
        getLocaleText("صنعت تاور در دنیا", "Tower industry in the world")
      );
    }

    if (props.path === "/towerCompany/3") {
      setLocTitle(
        getLocaleText(
          "تاور کمپانی در ایران و جهان",
          "Tower Company in Iran and the World"
        )
      );
    }

    if (props.path === "/b2s") {
      setLocTitle(
        getLocaleText(
          "احداث سایت به روش B2S",
          "Building a site using the B2S method"
        )
      );
    }

    if (props.path === "/blb") {
      setLocTitle(
        getLocaleText(
          "خدمات فروش به شرط اجاره BLB",
          "Sales service subject to BLB rental"
        )
      );
    }

    if (props.path === "/mll") {
      setLocTitle(
        getLocaleText(
          "اجاره حق بهره برداری و مدیریت سایت MLL",
          "Renting the right to operate and manage the MLL site"
        )
      );
    }

    if (props.path === "/operatorSites") {
      setLocTitle(
        getLocaleText(
          "نگهداری سایت های مخابراتی",
          "Maintenance of telecommunication sites"
        )
      );
    }

    if (props.path === "/beautification") {
      setLocTitle(
        getLocaleText(
          "زیباسازی سایت های مخابراتی",
          "Beautification of telecommunication sites"
        )
      );
    }

    if (props.path === "/futurePlan") {
      setLocTitle(getLocaleText("طرح های آینده", "Future Plans"));
    }

    if (props.path === "/contactUs") {
      setLocTitle(getLocaleText("ارتباط با ما", "Contact Us"));
    }

    if (props.path === "/society") {
      setLocTitle(getLocaleText("نقش ما در جامعه", "Our role in society"));
    }

    if (props.path === "/mission") {
      setLocTitle(getLocaleText("مأموریت، چشم انداز", "Mission, Vision"));
    }

    if (props.path === "/shareholders") {
      setLocTitle(
        getLocaleText("سهام داران و ذینفعان", "Shareholders and Stakeholders")
      );
    }

    if (props.path === "/beneficiaries") {
      setLocTitle(getLocaleText("جوایز، تندیس ها", "Awards, Statues"));
    }
  }, [props]);

  return (
    <>
      <header
        id="topnav"
        className="sticky defaultscroll"
        style={{ position: "relative" }}
      >
        <Menu />
        {locTitle === "صفحه اصلی" ? (
          <div
            className={` text-sm nav-centered  ${getLocaleText(
              "left-[5%] sm:left-[40%] right-[10%]",
              "right-[5%] sm:right-[40%] left-[6%]"
            )}`}
          >
            <h2 className="fw-bold">{getLocaleText(title, titleEN)}</h2>
            <h5
              className="mt-4 text-sm"
              style={{ textAlign: "justify", lineHeight: "40px" }}
            >
              {getLocaleText(suntitle, suntitleEN)}
            </h5>
            <span className="mt-4 see-all" style={{ margin: "unset" }}>
              <Link to="/towerCompany/0">
                <p>{getLocaleText("بیشتر بدانید", "More Info")}</p>
                <img
                  src="../assets/images/svg/arrow-left-white.svg"
                  style={{
                    transform: getLocaleText("none", "rotateZ(180deg)"),
                  }}
                ></img>
              </Link>
            </span>
          </div>
        ) : (
          <div
            className={`text-sm centered  ${getLocaleText(
              "left-0 sm:left-[40%]",
              "right-0 sm:right-[40%] left-[10%]"
            )}`}
          >
            <h2 className="font-bold">{locTitle}</h2>
          </div>
        )}
      </header>
    </>
  );
}

export default Navbar;
