import "./App.css";
import "./my-css.css";
import { Provider } from "react-redux";
import Routing from "./utils/routing";
import { store } from "./redux/store";
import { getLocaleText } from "./utils/getLocaleText";

function App() {
  return (
    <div className={getLocaleText("App", "App-En")}>
      <Provider store={store}>
        <Routing />
      </Provider>
    </div>
  );
}

export default App;
